<template>
  <v-container fluid>
    <v-row>
      <v-col align="right">
        <AuditsReportFilters
          :filters="filters"
          @applyFilters="
            filters = $event;
            getItems();
          "
        />
      </v-col>
      <v-col class="shrink">
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn
              color="msaBlue"
              class="white--text"
              @click="exportItems()"
              v-on="on"
            >
              <v-icon> mdi-download </v-icon>
            </v-btn>
          </template>
          <span>Export</span>
        </v-tooltip>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-card>
          <v-card-title class="msaBlue white--text">
            Web Login Audits
          </v-card-title>
          <v-card-text class="pa-0">
            <v-data-table
              :headers="headers"
              :footer-props="$constants.PAGINATION.DEFAULT_FOOTER_PROPS"
              :items="items"
              :server-items-length="itemsCount"
              :options.sync="options"
              must-sort
              @update:page="getItems"
              @update:items-per-page="getItems"
              @update:sort-by="getItems"
              @update:sort-desc="getItems"
            >
              <template v-slot:item="{ item }">
                <tr>
                  <td>
                    {{ item.companyName }}
                  </td>
                  <td>
                    {{ item.accountType }}
                  </td>
                  <td>
                    {{ item.fullname }}
                  </td>
                  <td>
                    {{ item.username }}
                  </td>
                  <td align="center">
                    {{ item.loginAt | filterAsLocalDate }}
                  </td>
                  <td align="center">
                    {{ item.loginAt | filterAsLocalTime }}
                  </td>
                  <td align="center">
                    <v-icon color="green">
                      {{ item.isWebAdministrator == 1 ? 'mdi-check' : '' }}
                    </v-icon>
                  </td>
                </tr>
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import AuditsReportFilters from '@/components/AuditsReportFilters.vue';
export default {
  name: 'WebLoginAuditsPage',
  components: {
    AuditsReportFilters,
  },
  data() {
    return {
      items: [],
      itemsCount: 0,
      filters: JSON.parse(
        JSON.stringify(this.$constants.AUDITS_REPORT_FILTERS),
      ),
      options: {
        sortBy: ['loginDate'],
        sortDesc: [true],
        page: this.$constants.PAGINATION.DEFAULT_PAGE,
        itemsPerPage: this.$constants.PAGINATION.DEFAULT_PERPAGE,
      },
      headers: [
        {
          text: 'Company',
          value: 'companyName',
          align: 'start',
          class: 'lightGrey',
        },
        {
          text: 'Account Type',
          value: 'accountType',
          align: 'start',
          class: 'lightGrey',
        },
        {
          text: 'Name',
          value: 'fullname',
          align: 'start',
          class: 'lightGrey',
        },
        {
          text: 'Username',
          value: 'username',
          align: 'start',
          class: 'lightGrey',
        },
        {
          text: 'Login Date',
          value: 'loginAt',
          align: 'center',
          class: 'lightGrey',
          width: '110px',
        },
        {
          text: 'Login Time',
          value: 'loginAt',
          align: 'center',
          class: 'lightGrey',
          width: '120px',
        },
        {
          text: 'Web Admin',
          value: 'isWebAdministrator',
          align: 'center',
          class: 'lightGrey',
          width: '120px',
        },
      ],
    };
  },
  methods: {
    getItems() {
      const params = {
        filters: this.filters,
        options: this.options,
        loaderText: 'loading...',
      };
      const url = 'get-web-login-audits?format=json';
      this.$axios.post(url, params).then((response) => {
        this.items = response.data.items;
        this.itemsCount = response.data.count;
      });
    },
    exportItems() {
      const params = {
        filters: this.filters,
        options: this.options,
        loaderText: 'loading...',
      };
      const options = {
        responseType: 'blob',
        withCredentials: process.env.VUE_APP_USE_WITH_CREDENTIALS,
      };
      const url = 'export-web-login-audits?format=json';
      this.$axios.post(url, params, options).then((response) => {
        this.$helpers.downloadFile(response.data, 'web-login-audits.xlsx');
      });
    },
  },
  mounted() {
    this.getItems();
  },
};
</script>

<style></style>
